body {
  background-color: #010101;
  font-family: 'Comic Neue';
  font-style: normal;
  color: #808EA2;;
}

.IntroSection {
  position: absolute;
  left: 4%;
  top:8%;
  width: 90%;
  height: 92%;
}

.portfolioPicture {
  position: absolute;
  left: 9%;
  top: 30%;
}

.vl-1 {
  border-left: 4px solid #808EA2;
  position: absolute;
  height: 60%;
  left: 43%;
  top: 28%;
}

.ValueText {
  position: absolute;
  left: 53%;
  top: 55%
}

.WorkSection {
  position: absolute;
  left: 4%;
  top:92%;
  width: 90%;
  height: 92%;
}

.KeyText-2{
  position: relative;
  height: auto;
  left: 25%;
  top: 45%;
}

.vl-2 {
  border-left: 4px solid #808EA2;
  position: relative;
  height: 60%;
  left: 43%;
  top: 10%;
}

.ValueText-2 {
  position: absolute;
  height: auto;
  left: 53%;
  top: 45%
}

.ContactSection {
  position: absolute;
  left: 4%;
  top:178%;
  width: 90%;
  height: 92%;
}


.KeyText-3{
  position: relative;
  height: auto;
  left: 25%;
  top: 45%;
}

.vl-3 {
  border-left: 4px solid #808EA2;
  position: relative;
  height: 60%;
  left: 43%;
  top: 10%;
}

.ValueText-3 {
  position: absolute;
  height: auto;
  left: 53%;
  top: 45%
}

.NavigationBar {
  /* NavBar */
  position: absolute;
  width: 95%;
  left: 4%;
  top: 4%;
  height: 8%;
}

.button {
  position: absolute;
  height: 32.0px;
  width: 160px;
  border-radius: 10px;  
  background-color: #010101;

  font-family: 'Comic Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;

  color: #788598; 
  /*border: 1px solid #B3DAF5; */

}


.homeButton {
  /* HomeButton */

  position: absolute;
  border: 0px solid
}

.aboutButton {
  left: 55%;
}

.workButton {
  
  left: 70%;
}

.contactButton {
  left: 85%;
}


